.select-applicable-assets {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: 1fr;
  gap: 1.125rem;

  &--asset-item {
    padding: 1.125rem;
    display: grid;
    grid-template-areas:
      "title title"
      "floor floor"
      "room select";
    grid-template-rows: 1fr auto auto;
    grid-template-columns: 1fr auto;

    &:hover {
      background-color: #F1F4F9;
    }

    p {
      padding-bottom: 0;
    }

    &--title {
      grid-area: title;
    }
    &--floor {
      grid-area: floor
    }
    &--room {
      grid-area: room
    }
    &--select {
      grid-area: select
    }
  }
}

.measure-details-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  row-gap: 1.125rem;
  column-gap: 2.25rem;

  .temperature-target, .temperature-target-wide {
    input {
      max-width: 8rem;
    }
  }

  .water-type, .temperature-target, .temperature-target-wide {
    .form-group, .form-check.abc-checkbox {
      margin-bottom: 0;
    }
  }

  .water-type {
    grid-column: 1;
    align-self: center;
  }
}

.migrate-locations {
  .alert {
    padding: .375rem .75rem;
  }
}
