$control-measure-colors: (
        1: #1A4F95,
        2: #65BCC3,
        4: #5E2F76,
        5: #DB622B,
        6: #B8252A,
        7: #C4344D,
        8: #43a5cc,
        9: #cc9e43,
        10: #848484,
        12: #6E316B,
);
$bg-color: rgba(80, 193, 239, 0.12);
$bg-color-light: rgba(80, 193, 239, 0.06);

.risk-assessment-read-only-asset-register {
  table {
    margin-bottom: 0;
  }

  thead {
    z-index: 999;

    th {
      border: none;
      box-shadow: inset 0 2px 0 #fff, inset 0 -2px 0 #dee2e6;

      &.disclosure {
        width: 74px;
      }

      &.extra-info {
        width: 64px;
      }

      &.control-measures {
        width: 50ch;
      }
    }
  }

  tbody {
    tr:first-of-type {
      td {
        border-top: 0;
      }
    }

    tr.asset {
      cursor: pointer;

      &.is-active {
        td {
          background-color: $bg-color-light;
          border-bottom: 0;
          box-shadow: none !important;

          &.disclosure {
            i {
                transform: rotate(90deg);
            }
          }
        }
      }

      &:hover {
        &.is-active {
          td {
            background-color: $bg-color;
          }
        }
      }
    }

    tr.asset-details {
      background: none !important;
      display: none;
      overflow: clip;

      // Removes hover background
      & > * {
        box-shadow: none !important;
        padding: 0 !important;
      }

      td {
        border-top: 0;
      }

      .asset-details-container {
        background-color: #fff;
        border-top: 1px solid #D3D3D3;
        border-left: 1px solid #D3D3D3;
        display: none;
        margin-inline-start: 2rem;
        padding: 1rem;
      }

      .answer {
        margin: 0 0 1rem;
      }

      .answer-heading {
        font-weight: 600;
        margin: 0;
      }

      .answer-image-thumbnail {
        height: 240px;
        max-width: 100%;
        min-width: 120px;
        object-fit: cover;
        width: auto;
      }

      &:has(.answer--document + .answer--document) {
        .answer--document {
          display: inline-flex;

          &:nth-of-type(odd) {
            max-width: 50%;
          }
          &:nth-child(even) {
            margin-left: 1rem;
            max-width: calc(50% - 1rem);
          }
        }
      }

      table.installation-modifications {
        width: 100%;

        thead {
          tr {
            background-color: initial;
          }
        }

        th {
          &.im-images {
            width: 320px;
          }
          &.im-status {
            width: 64px;
          }
          &.im-materials {
            width: 192px;
          }
        }

        tbody {
          tr:hover {
            td {
              background-color: $bg-color-light;
            }
          }
        }

        td {
          background-color: #fff;
          vertical-align: top;

          &.im-status {
            text-align: center;
          }

          &.im-materials {
            table {
              td {
                padding: .125rem .25rem;
              }
            }
            .badge {
              transform: translateY(-2px);
            }
          }
        }

        .im-image-group {
          display: block;
          margin: 0 0 .5rem;

          img {
            height: 84px;
            object-fit: cover;
            width: calc(50% - 4px);
          }

          &__heading {
            margin: 2px 0 .25rem;
          }
        }
      }

      &.is-active {
        display: table-row;

        td {
          background-color: $bg-color-light;
        }
      }
    }
  }

  td {
    &.disclosure {
      color: var(--nav, #6c6b6b);
      font-size: .75rem;
      text-align: center;

      i {
        transition: transform 300ms ease-in-out;
      }
    }
  }

  .control-measure-label {
    border-radius: 4px;
    border: 1px solid transparent;
    cursor: default;
    font-weight: normal;
    display: inline-block;
    padding: 4px;
    pointer-events: none;
    margin: 2px;

    @each $cm, $color in $control-measure-colors {
      &--#{$cm} {
        color: darken($color, .5);
        border-color: $color;
        background-color: transparentize($color, .9);
      }
    }
  }

  .skeleton {
    background: none !important;
    cursor: progress;

    > * {
      box-shadow: none !important;
    }

    .skeleton-item {
      animation: skeleton-loading ease-in-out 1000ms infinite alternate;
      background: linear-gradient(90deg, var(--gray-400, #ced4da) 20%, #efefefaa, var(--gray-400, #ced4da) 100%) right / 300% 100%;
      border-radius: 2px;
      height: 1.5em;
    }
  }

  a[data-fancybox] {
    text-decoration: none;
  }
}

@keyframes skeleton-loading {
  to {
    background-position: left;
  }
}
