@import "@yaireo/tagify/dist/tagify.css";
@import "../../../scss/theme/default/variables";


.tagify {
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;

  --tag-edit-btn-bg: none;
  --tag-edit-btn-bg--hover: #ffc107;
  --tag-edit-btn-color: $black;
}


.tagify__tag__editBtn {
  order: 5;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  cursor: pointer;
  font: 14px/1 Arial;
  background: var(--tag-edit-btn-bg);
  color: var(--tag-edit-btn-color);
  width: 14px;
  height: 14px;
  margin-inline: auto 4.6666666667px;
  overflow: hidden;
  transition: .2s ease-out;
  
  &::after {
    font-family: var(--fa-style-family, "Font Awesome 6 Free");
    font-size: 8px;
    font-weight: var(--fa-style, 900);
    content: "\f303";
    transition: .3s, color 0s
  }
  
  &:hover {
    color: #fff;
    background: var(--tag-edit-btn-bg--hover)
  }
  
  &:hover + div > span {
    opacity: .5
  }
  
  &:hover + div::before {
    box-shadow: 0 0 0 var(--tag-inset-shadow-size) var(--tag-edit-bg, rgba(211, 148, 148, .3)) inset !important;
    transition: box-shadow .2s
  }
}
